Vue.component('Modal', {
    template: '#modal-template',

    props: [
        'show',
        'onClose',
        'large',
        'isCustomSwal',
        'xlarge',
    ],

    computed: {
        containerClass() {
            if (this.xlarge) {
                return 'modal-container-xlarge';
            }

            return this.large
                ? 'modal-container-large'
                : 'modal-container';
        }
    },

    watch: {
        //added this watch to modal to prevent <body> scroll
        show() {
            if(this.show) {
                document.body.style.overflow = 'hidden';
                return;
            }

            document.body.style.overflow = '';
        }
    },

    methods: {
        close() {
            if (!this.onClose) {
                return this.show = false;
            }

            this.onClose();
        }
    },

    ready() {
        document.addEventListener("keydown", (e) => {
            if (this.show && e.keyCode == 27) {
                this.close();
            }
        });
    }
});
