new Vue({
    el: "#login",

    methods: {
        showPassword() {
            $(`#password`).attr('type') == 'password'
                ? $(`#password`).attr('type', 'text')
                : $(`#password`).attr('type', 'password');

            $(`#show-fa-eye`).toggleClass('fa-eye-slash');
        },
    },
});
